import axios from 'axios';

const getDefaultState = () => {
    return {
        
       // isReturnToBilling: false,
    }
};

const state = getDefaultState();



const getters = {
  tenants: state => state.tenants
};

const actions = {
  async loadTenants({ commit, dispatch, rootState }) {
    axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
    try {
        let config = {
            headers: {
                Authorization: rootState.auth.bearer
            },
        };
        let payload = {

        };

      const response = await axios.post('/v2/cmv/tenants', payload, config);
      //console.log('API Response:', response.data); ///console.log(response.data);
      commit('setTenants', response.data);
    } catch (error) {
      console.error('Error loading tenants:', error);
    }
  }
};

const mutations = {
  setTenants(state, tenants) {
    state.tenants = tenants;
  },

  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};