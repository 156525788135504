import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import { getAuthToken, checkCurrentAuthenticatedUser } from '@/services/auth';
//import { configAuth } from '@/services/auth'
import { isMobileDevice } from '@/services/deviceUtils'; // Import the utility function


// Lazy load components
const loadView = (view) => () => import(`@/views/${view}.vue`);
const loadComponent = (component) => () => import(`@/components/${component}.vue`);

// Define roles
const roleNames = {
  TenantAdmin: 'TenantAdmin',
  Facilitator: 'Facilitator',
  Participant: 'Participant',
};

// Define routes
const routes = [
  { path: '/', name: 'Start', component: loadView('Start'), meta: { title: 'Start', requiresAuth: false } },
  { path: '/dashboard', name: 'Dashboard', component: loadView('Dashboard'), meta: { title: 'Dashboard', requiresAuth: true }, beforeEnter: redirectToMobile('MobileDashboard') },
  { path: '/m-dashboard', name: 'MobileDashboard', component: loadView('MobileDashboard'), meta: { title: 'Mobile Dashboard', requiresAuth: true } },
  { path: '/treasure-chest', name: 'TreasureChest', component: loadView('TreasureChest'), meta: { title: 'TreasureChest', requiresAuth: true }, beforeEnter: redirectToMobile('MobileTreasureChest') },
  { path: '/m-treasure-chest', name: 'MobileTreasureChest', component: loadView('MobileTreasureChest'), meta: { title: 'Mobile TreasureChest', requiresAuth: true } },
  { path: '/journey-creation/:id?', name: 'JourneyCreation', component: loadView('JourneyCreation'), meta: { title: 'JourneyCreation', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] } },
  { path: '/journey/map/:id?', name: 'JourneyMap', component: loadView('JourneyMap'), meta: { title: 'JourneyMap', requiresAuth: true } },
  { path: '/journey/view/:id', name: 'JourneyView', component: loadView('JourneyView'), meta: { title: 'JourneyView', requiresAuth: true } },
  { path: '/m-journey/view/:id?', name: 'MobileJourneyView', component: loadView('MobileJourneyView'), meta: { title: 'Mobile Journey View', requiresAuth: true } },
  { path: '/m-journey/map/:id?', name: 'MobileJourneyMap', component: loadView('MobileJourneyMap'), meta: { title: 'Mobile Journey Map', requiresAuth: true } },
  { path: '/journey-template/map/:id', name: 'JourneyReusableTemplateMap', component: loadView('JourneyMap'), meta: { title: 'JourneyReusableTemplateMap', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] } },
  { path: '/journey-template/view/:id', name: 'JourneyReusableTemplateView', component: loadView('JourneyView'), meta: { title: 'JourneyReusableTemplateView', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin, roleNames.Facilitator] } },
  { path: '/admin', name: 'Admin', component: loadView('Admin'), meta: { title: 'Admin', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin] }, children: [
    { path: 'tenant-settings', name: 'AdminTenantSettings', component: loadComponent('admin/TenantSettings'), meta: { title: 'Tenant Settings', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin] } },
    { path: 'user-management', name: 'AdminUserManagement', component: loadComponent('admin/UserManagement'), meta: { title: 'User Management', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin] } },
    { path: 'journey-management', name: 'AdminJourneyManagement', component: loadComponent('admin/JourneyManagement'), meta: { title: 'Journey Management', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin] } },
    { path: 'billing', name: 'AdminBilling', component: loadComponent('admin/Billing'), meta: { title: 'Billing', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin] } },
  ]},
  { path: '/account', name: 'Account', component: loadView('Account'), meta: { title: 'Account', requiresAuth: true }, beforeEnter: redirectToMobile('MobileAccount'), children: [
    { path: 'user-management', name: 'AccountUserManagement', component: loadComponent('account/UserManagement'), meta: { title: 'User Management', requiresAuth: true } },
    { path: 'journey-management', name: 'AccountJourneyManagement', component: loadComponent('account/JourneyManagementMyAccount'), meta: { title: 'Journey Management', requiresAuth: true } },
    { path: 'billing', name: 'AccountBilling', component: loadComponent('account/Billing'), meta: { title: 'Billing', requiresAuth: true } },
  ]},
  { path: '/m-account', name: 'MobileAccount', component: loadView('MobileAccount'), meta: { title: 'Mobile Account', requiresAuth: true } },
  { path: '/settings', name: 'Settings', component: loadView('Settings'), meta: { title: 'Settings', requiresAuth: true }, beforeEnter: redirectToMobile('MobileSettings') },
  { path: '/m-settings', name: 'MobileSettings', component: loadView('MobileSettings'), meta: { title: 'Settings', requiresAuth: true } },
  { path: '/:pathMatch(.*)*', name: '404', component: loadView('PageNotFound'), meta: { title: 'PageNotFound', requiresAuth: false } },
  { path: '/logon', name: 'Logon', component: loadComponent('auth/Logon'), meta: { title: 'Logon', requiresAuth: false }, beforeEnter: async (to, from, next) => {
    try {
      const user_route = await checkCurrentAuthenticatedUser();
      if (user_route) {
        next({ name: isMobileDevice() ? 'MobileDashboard' : 'Dashboard' });
      } else {
        next();
      }
    } catch {
      next();
    }
  }},
  { path: '/cmv', name: 'Cmv', component: loadView('Cmv'), meta: { title: 'CMV Admin Center', requiresAuth: true, allowedRoles: [roleNames.TenantAdmin] }, beforeEnter: (to, from, next) => {
    const user = store.state.auth.userInfo;
    const allowedEmails = ['mhairer@gmail.com', 'mail@rupertseewald.com'];
    const tenant = store.state.tenant.tenantinfo;
    const allowedTenants = ['4fcb7cdd-9d17-4005-90b5-a047934bfce9', 'aab284e9-8cad-44e4-ac48-e2a6d86ae9d5'];
    if (user && allowedEmails.includes(user.email) && tenant && allowedTenants.includes(tenant.id)) {
      next();
    } else {
      next({ path: '/' });
    }
  }},
];

// Helper function to redirect to mobile routes
function redirectToMobile(mobileRouteName) {
  return (to, from, next) => {
    if (isMobileDevice()) {
      next({ name: mobileRouteName });
    } else {
      next();
    }
  };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const isUserAllowedBasedOnRole = (allowedRoles) => {
  const userRoles = store.getters['auth/getCurrentUserRoles'];
  return allowedRoles.some(role => userRoles[role]);
};

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
    //console.log(`Global beforeEach: Navigating to ${to.path} from ${from.path}`);
  }
  
  store.dispatch('loading/setLoading', true);

  if (to.meta.requiresAuth) {
    try {
      const user_route = await checkCurrentAuthenticatedUser();
      //console.log('beforEach and with a requiredsAuth setting');
      //console.log('user_route was checkCurrentAuthenticateeUser: ', user_route);

      if (user_route) {
        //console.log('user_route defined and now getAuthToken')
        await getAuthToken(); // Ensure we have the latest token

        // Check if the route has role restrictions
        if (to.meta.allowedRoles && !isUserAllowedBasedOnRole(to.meta.allowedRoles)) {
          //console.log('check is allowedRoles');
          // Redirect to a default page if the user doesn't have the required role
          next({ path: '/' });
        } else {
          //console.log('no role needed redirect to deep ling not the logon.')
          // Allow access to the route
          next();
        }
      } else {
        //console.log('user_route not defined and direct to logon');
        // Redirect to the login page if the user is not authenticated
        next({ path: '/logon' });
      }
    } catch (error) {
      //console.error('Authentication error:', error);
      next({ path: '/logon' });
    }
  } else {
    // Allow access to routes that do not require authentication
    next();
  }
});


router.afterEach(() => {
  store.dispatch('loading/setLoading', false);
});


export default router;
